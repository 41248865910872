// src/sass/main.scss

/*
html {
    min-height: calc(100% + env(safe-area-inset-top));
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);

}
*/

/* roboto-condensed-300 - latin-ext_latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    src: local(''),
    url('/fonts/RobotoCondensed/roboto-condensed-v19-latin-ext_latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/fonts/RobotoCondensed/roboto-condensed-v19-latin-ext_latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-condensed-300italic - latin-ext_latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 300;
    src: local(''),
    url('/fonts/RobotoCondensed/roboto-condensed-v19-latin-ext_latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/fonts/RobotoCondensed/roboto-condensed-v19-latin-ext_latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-condensed-regular - latin-ext_latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('/fonts/RobotoCondensed/roboto-condensed-v19-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/fonts/RobotoCondensed/roboto-condensed-v19-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-condensed-700 - latin-ext_latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    src: local(''),
    url('/fonts/RobotoCondensed/roboto-condensed-v19-latin-ext_latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/fonts/RobotoCondensed/roboto-condensed-v19-latin-ext_latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-condensed-italic - latin-ext_latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 400;
    src: local(''),
    url('/fonts/RobotoCondensed/roboto-condensed-v19-latin-ext_latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/fonts/RobotoCondensed/roboto-condensed-v19-latin-ext_latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-condensed-700italic - latin-ext_latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 700;
    src: local(''),
    url('/fonts/RobotoCondensed/roboto-condensed-v19-latin-ext_latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/fonts/RobotoCondensed/roboto-condensed-v19-latin-ext_latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}




/* latin-ext */
@font-face {
  font-family: 'Fjalla One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/Fjalla_One/FjallaOne-Regular.ttf') format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */

@font-face {
  font-family: 'Fjalla One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Fjalla One'), local('FjallaOne-Regular'), url(/fonts/Fjalla_One/FjallaOne-Regular.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
/*@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/Josefin_Sans/JosefinSans-RegularItalic.ttf) format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}*/
/* latin */
/*@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/Josefin_Sans/JosefinSans-RegularItalic.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}*/
/* latin-ext */
/*@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/Josefin_Sans/JosefinSans-BoldItalic.ttf) format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}*/
/*
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/Josefin_Sans/static/JosefinSans-BoldItalic.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/Josefin_Sans/static/JosefinSans-BoldItalic.ttf) format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/Josefin_Sans/static/JosefinSans-BoldItalic.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/Josefin_Sans/static/JosefinSans-Light.ttf) format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/Josefin_Sans/static/JosefinSans-Light.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/Josefin_Sans/static/JosefinSans-Regular.ttf) format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/Josefin_Sans/static/JosefinSans-Regular.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/Josefin_Sans/static/JosefinSans-SemiBold.ttf) format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/Josefin_Sans/static/JosefinSans-SemiBold.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Syncopate';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Syncopate Regular'), local('Syncopate-Regular'), url(/fonts/Syncopate/Syncopate-Regular.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Syncopate';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Syncopate Bold'), local('Syncopate-Bold'), url(/fonts/Syncopate/Syncopate-Bold.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Carter One';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/CarterOne/CarterOne.ttf) format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}*/

/* latin-ext */
@font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/fonts/Josefin_Sans/static/JosefinSans-Regular.ttf) format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/Anton/1Ptgg87LROyAm3K9-C8QSw.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/Anton/1Ptgg87LROyAm3Kz-C8.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
    font-family: 'Patua One';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/PatuaOne/ZXuke1cDvLCKLDcimxB44_lu.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


// The variables you want to modify
//$font-size-root: 54px;

// For updating SASS lists
//$material-light: map-merge($material-light, ( cards: blue ));
//$btn-border-radius: 20px;
$body-font-family: 'Roboto Condensed', Arial, sans-serif  !default;
//$heading-font-family: 'Josefin Sans', sans-serif;
$heading-font-family: "Anton", Arial, sans-serif;
$font-size-root: 18px !default;


@import '~vuetify/src/styles/styles.sass';

@each $heading, $style in $headings {
    $headings: map-merge($headings, ($heading: map-merge($style, ('font-family': $heading-font-family))));
}
//$headings: map-merge($headings, ('h1': ('font-family': $heading-font-family)));


.primary{
    color: #000000 !important;
}

.v-btn > .v-btn__content .v-icon {
  text-shadow: 0 1px 2px black;
}

.v-card-title{
  font-family: $heading-font-family;
}

h1{
    font-family: $heading-font-family;
    color: #E2E0E1;
}

header.apptoolbar{
    background: -webkit-linear-gradient(to top, rgba(66, 65, 71, 1), rgba(41, 41, 51, 1));
    background: linear-gradient(to top, rgba(66, 65, 71, 1), rgba(41, 41, 51, 1));
    background-color: inherit !important;
    position:fixed;
    left: 0;
    right: 0;

    z-index: 1;
    top: 0;
    /*@supports (top: calc( 10px + env(safe-area-inset-top) )) {
        top: calc( 10px + env(safe-area-inset-top) )
    }*/
}

/*
.v-main{
    margin-top: 70px;
    @supports (margin-top: calc( 60px + env(safe-area-inset-top) )) {
        margin-top: calc( 60px + env(safe-area-inset-top) )
    }
}*/

.v-main{
    margin-bottom: 56px;
}

.v-toolbar__content {

}
.theme--dark.v-application {
    background: #1F1F1F;
    color: #FFFFFF;
}
body{
    background-color: #1F1F1F;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    word-break: normal;
}
.v-toolbar__content .row{


}

nav.v-navigation-drawer {
}
/*.v-toolbar__extension {
    margin: 0 env(safe-area-inset-right) 0 env(safe-area-inset-top);
}*/

:root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
}

.v-card__actions{
    font-family: $heading-font-family;
}

.v-card__title{
    font-family: $heading-font-family;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    word-break: normal;
}

.v-btn{
    //border-radius:4px!important;
}
.openseadragon-canvas:focus {
    outline: none;
}

.tiptap-vuetify-editor__content .ProseMirror p {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
p:empty::before {
    content: '';
    display: inline-block;
}
